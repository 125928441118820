import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@middesk/components'
import { PartnerReferral } from '../../types'

const { typography, colors, spacing } = theme

const SidebarWrapper = styled.div`
  padding: 20px 0;
  max-width: 280px;
`

const HeaderWrapper = styled.div`
  color: ${colors.graphite};
  font-size: ${typography.sizes.large};
  max-width: 400px;
`

const PriceContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: ${spacing.xsmall};
  margin-top: ${spacing.large};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${typography.sizes.medium};
`

const StyledIcon = styled(Icon)`
  margin-top: ${spacing.xxsmall};
`

const GraphiteText = styled.div`
  color: ${colors.graphite};
`

const KarlText = styled.div`
  color: ${colors.karl};
`

const Sidebar = ({
  partnerReferral
}: {
  partnerReferral: PartnerReferral | null
}): JSX.Element => {
  if (!partnerReferral || partnerReferral.billing.reseller) {
    return <></>
  }

  const {
    ui_price: uiPrice,
    wh_price: whPrice,
    local_price: localPrice
  } = partnerReferral.billing

  return (
    <SidebarWrapper>
      <HeaderWrapper>
        Set up all the state accounts that you need to operate and pay employees
        in minutes.
      </HeaderWrapper>
      <PriceContainer>
        <StyledIcon name='success' />
        <Column>
          <GraphiteText>State Unemployment Insurance</GraphiteText>
          <KarlText>${uiPrice / 100.0}/registration</KarlText>
        </Column>
      </PriceContainer>
      <PriceContainer>
        <StyledIcon name='success' />
        <Column>
          <GraphiteText>State Withholdings Tax</GraphiteText>
          <KarlText>${whPrice / 100.0}/registration</KarlText>
        </Column>
      </PriceContainer>
      {localPrice && (
        <PriceContainer>
          <StyledIcon name='success' />
          <Column>
            <GraphiteText>Local Tax</GraphiteText>
            <KarlText>${localPrice / 100.0}/registration</KarlText>
          </Column>
        </PriceContainer>
      )}
    </SidebarWrapper>
  )
}

export default Sidebar
