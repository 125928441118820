import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { theme } from '@middesk/components'
import { SidebarProps } from '../../types'
import CreateStateFilingSideBar from '../CreateStateFilingSidebar'
import OnboardingDialogue from '../OnboardingDialogue'
import SystemSidebar from '../System/Sidebar'
import { APPLICATION_ROUTE, GUEST_APPLICATION_ROUTE } from '../../lib/constants'
import CompanyLinks from './CompanyLinks'
import { CompanyContext } from '../CompanyProvider'

const { colors } = theme

const SESSION_ROUTES: Array<string> = ['/signup', '/confirm-email']

const Sidebar = styled(
  ({
    className,
    hasStockTablePage,
    hasDynamicStatePage,
    showPaymentInformation,
    requiresSosRegistration,
    showPayrollReports,
    current,
    logo,
    fetchingApplication,
    hideMenu,
    localJurisdictionRegistrations,
    gustoIntake
  }: SidebarProps): JSX.Element => {
    const {
      location: { pathname }
    } = useHistory()

    const { activeCompany } = useContext(CompanyContext)
    const isFindBusiness = SESSION_ROUTES.includes(pathname)
    const isCreateApplicationPath = [
      APPLICATION_ROUTE,
      GUEST_APPLICATION_ROUTE
    ].some((pattern: RegExp) => pathname.match(pattern))

    let Content

    if (isFindBusiness) {
      Content = <OnboardingDialogue />
    } else if (isCreateApplicationPath) {
      Content = (
        <CreateStateFilingSideBar
          {...{
            hasStockTablePage,
            requiresSosRegistration,
            showPaymentInformation,
            showPayrollReports,
            hasDynamicStatePage,
            localJurisdictionRegistrations,
            current,
            gustoIntake
          }}
        />
      )
    } else if (activeCompany) {
      Content = <CompanyLinks companyId={activeCompany.id} />
    }

    const backgroundColor = gustoIntake ? colors.white : colors.frostLight

    return (
      <SystemSidebar
        {...{ className, logo, fetchingApplication, hideMenu, backgroundColor }}
      >
        {Content}
      </SystemSidebar>
    )
  }
)``

export default Sidebar
