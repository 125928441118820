import React, { FC, useMemo } from 'react'
import { theme } from '@middesk/components'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import {
  AgentApplication,
  FormValues,
  LocalRegistrationPageProps,
  Question,
  TransactionalPricingData
} from '../types'
import { TYPOGRAPHY_WEIGHTS } from './System/Typography'

const { spacing, colors } = theme

export interface PageProps {
  application: AgentApplication | null
  pricingData: TransactionalPricingData | undefined
  pageMap: any
  requiresSosRegistration: boolean
  hasDynamicStatePage: boolean
  showSOSPaymentInformation: boolean
  onCancel: () => void
  onNext: (values: FormValues, submit?: boolean) => void
  updateValidationSchema: (schema: yup.ObjectSchema) => void
  isSubmitting: boolean
  error?: string | null
  logo?: string
  progress?: string
  pageNumber: number
  firstLocalPageAt?: number
  localJurisdictionRegistrations?: LocalRegistrationPageProps[]
  saveApplication: (values: any, submit: boolean) => void
  validationSchema: yup.ObjectSchema
  gustoIntake: boolean
}

export type Page = FC<PageProps> & {
  pageName?: string
  validationSchema?: any
  questions?: Question[]
  title: (
    application: AgentApplication,
    localJurisdictionLabel?: string
  ) => string | undefined
  description: (
    application: AgentApplication,
    localJurisdictionLabel?: string
  ) => string | undefined
}

export const Title = styled.h2`
  color: ${colors.graphite};
  font-size: 1.75rem;
  font-weight: 300;
  margin: 30px 0 10px;
`

export const Description = styled.p`
  color: ${colors.graphite};
  margin-bottom: 30px;
`

export const SectionHeader = styled.div`
  font-size: 1.25rem;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  margin: ${spacing.compact} 0 30px;
`

export const GapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.large};
`

export const Error = styled(({ validationSchema, className }) => {
  const fields = useMemo(() => flattenSchema([], validationSchema.fields), [
    validationSchema
  ])
  return (
    <div className={className}>
      {fields.map((k: string) => {
        return (
          <div key={k}>
            <ErrorMessage name={k} />
          </div>
        )
      })}
    </div>
  )
})`
  color: ${colors.red};
  display: flex;
  flex-direction: column;
`

const flattenSchema = (keys: Array<string>, schema: any, prefix?: string) => {
  for (const key of Object.keys(schema)) {
    const keyName = prefix ? prefix + '.' + key : key
    if (schema[key] && 'fields' in schema[key]) {
      keys.concat(flattenSchema(keys, schema[key].fields, keyName))
    } else {
      keys.push(keyName)
    }
  }
  return keys
}
