import amplitude from 'amplitude-js'
import { omit, padStart, pick, capitalize, isEmpty, compact } from 'lodash'
import pluralize from 'pluralize'
import moment from 'moment'
import { TextFieldProps, theme } from '@middesk/components'

import {
  Address,
  Individual,
  StockTable,
  StateAgency,
  Question,
  Action,
  Stock,
  JobTitle,
  RegistrationType,
  Agency,
  OrganizationEntityType,
  DateValidation,
  Owner,
  TransactionalPricingData,
  LocalRegistrationPageProps,
  LocalQuestion,
  DatePartQuestion
} from '../types'
import {
  ADDRESS_TYPES,
  ACTION_TYPES,
  APPLICATION_ACTION_TYPES,
  GUEST_APPLICATION_ROUTE,
  GUEST_TRANSFER_ROUTE,
  GUEST_INVITATIONS_ROUTE,
  DEPRECATED_INVITATIONS_ROUTE,
  SOS_REGISTRATION_ROUTE,
  SOS_CONFIRMATION_ROUTE,
  LLC_PREFIX,
  LLC_LONG,
  CHANGED_ENTITY_TYPE,
  INFORMATION_REQUESTED,
  BLOCKED_BY_GOVERNMENT,
  MIDDESK_PROCESSING,
  GOVERNMENT_PROCESSING,
  COMPLETED,
  JOB_TITLES,
  SECRETARY_ROLES,
  TREASURER_ROLES,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  PAYROLL_REGISTRATION_TYPES,
  BOTH_SUI_AND_SWH_TYPE,
  ENTITY_TYPES,
  CORPORATION,
  AGENT_INDIVIDUAL,
  AGENT_BUSINESS,
  UI_JURISDICTION_SLUG_IDENTIFIER,
  WH_JURISDICTION_SLUG_IDENTIFIER
} from './constants'
import { TEST_SIGNATURE } from './testSignature'

const { colors } = theme

export const BASE_64_IDENTIFIER = 'data:image/png;base64,'

export const formatAddress = (
  { address_line1, address_line2, city, state, postal_code }: Address,
  delimiter = ', '
) => {
  let address = null

  if (address_line1) {
    address = `${address_line1}${delimiter}`

    if (address_line2) {
      address += `${address_line2}${delimiter}`
    }

    if (city) {
      address += `${city}, ${state} ${postal_code}`
    }
  }

  return address
}

export const applicationPayload = (
  values: Record<string, any>,
  questions: Question[],
  state: string,
  localQuestionsMap: Record<string, LocalQuestion[]>
) => {
  const flatQuestions: Record<string, any>[] = []

  if (values.questions[state]) {
    questions.forEach((question: Question) => {
      const isDocument = question.type === 'document'
      const questionValue = values.questions[state][question.key]
      const value = isDocument ? '' : questionValue
      const document = isDocument
        ? (questionValue || '').replace(BASE_64_IDENTIFIER, '')
        : ''

      flatQuestions.push({
        state,
        question: question.label,
        key: question.key,
        value,
        document
      })
    })
  }

  const localJurisdictionSlugs = localJurisdictions(values.jurisdictions)

  const localQuestions: Record<string, any>[] = []
  localJurisdictionSlugs.forEach((jurisdictionSlug: string) => {
    const jurisdictionQuestions = localQuestionsMap[jurisdictionSlug]

    Object.keys(values.questions[jurisdictionSlug] || {}).forEach(
      (key: string) => {
        const question = jurisdictionQuestions.find(
          jurisdictionQuestion => jurisdictionQuestion.key === key
        )?.label

        localQuestions.push({
          state,
          key,
          question,
          value: values.questions[jurisdictionSlug][key],
          jurisdiction: jurisdictionSlug
        })
      }
    )
  })

  const allSecondaryAddresses: Address[] = []
  for (const addressState in values.secondary_addresses) {
    allSecondaryAddresses.push(...values.secondary_addresses[addressState])
  }

  // Clear unselected tax registration types
  const tax_registrations = values.tax_registrations
  const selectedStateJurisdictions = stateJurisdictions(values.jurisdictions)
  if (!selectedStateJurisdictions.includes(BOTH_SUI_AND_SWH_TYPE)) {
    tax_registrations[0][taxTypeIntentKey(BOTH_SUI_AND_SWH_TYPE)] = null
  }
  if (!selectedStateJurisdictions.includes(STATE_TAX_WITHHOLDINGS_TYPE)) {
    tax_registrations[0][taxTypeIntentKey(STATE_TAX_WITHHOLDINGS_TYPE)] = null
  }
  if (!selectedStateJurisdictions.includes(STATE_UNEMPLOYMENT_INSURANCE_TYPE)) {
    tax_registrations[0][
      taxTypeIntentKey(STATE_UNEMPLOYMENT_INSURANCE_TYPE)
    ] = null
  }

  // Add selected local tax registrations
  const agency_registrations = agencyRegistrationFromJurisdictions(
    state,
    localJurisdictionSlugs
  )

  return {
    ...omit(values, [
      'totals',
      'state_locations_count',
      'has_state_locations',
      'legal_entity_changed'
    ]),
    tax_registrations,
    agency_registrations,
    legal_entity_changed: values.registration_reason === CHANGED_ENTITY_TYPE,
    document_501c3: (values.document_501c3 || '').replace(
      BASE_64_IDENTIFIER,
      ''
    ),
    addresses: [
      values.primary_address,
      values.mailing_address,
      values.previous_primary_address,
      ...allSecondaryAddresses
    ],
    questions: flatQuestions,
    owners: values.owners.map((o: Owner) => {
      if (o.object === AGENT_BUSINESS) return o
      return {
        ...o,
        signature: (o.signature || '').replace(BASE_64_IDENTIFIER, '')
      }
    }),
    local_details: Object.values(values.local_details),
    local_questions: localQuestions
  }
}

const agencyRegistrationFromJurisdictions = (
  state: string,
  jurisdictions: string[]
) => {
  return jurisdictions.length > 0 ? [{ state, jurisdictions }] : []
}

export const selectApplicationKeys = (applicationJson: Record<string, any>) => {
  const agencyRegistrations =
    applicationJson.saved_agency_registrations &&
    applicationJson.saved_agency_registrations[0]
  const stateRegistrations =
    applicationJson.saved_tax_registrations &&
    applicationJson.saved_tax_registrations[0]
  const stateJurisdictions: string[] = []
  const localJurisdictions = agencyRegistrations
    ? agencyRegistrations.jurisdictions
    : []

  if (stateRegistrations) {
    if (stateRegistrations[taxTypeIntentKey(BOTH_SUI_AND_SWH_TYPE)]) {
      stateJurisdictions.push(BOTH_SUI_AND_SWH_TYPE)
    }
    if (stateRegistrations[taxTypeIntentKey(STATE_TAX_WITHHOLDINGS_TYPE)]) {
      stateJurisdictions.push(STATE_TAX_WITHHOLDINGS_TYPE)
    }
    if (
      stateRegistrations[taxTypeIntentKey(STATE_UNEMPLOYMENT_INSURANCE_TYPE)]
    ) {
      stateJurisdictions.push(STATE_UNEMPLOYMENT_INSURANCE_TYPE)
    }
  }

  return {
    ...pick(applicationJson, [
      'accounting_basis',
      'acquisition_date',
      'acquisition_legal_name',
      'acquisition_fein',
      'acquisition_state_jurisdiction_ein',
      'addresses',
      'application_type',
      'company_id',
      'contact_email',
      'contact_name',
      'contact_phone_number',
      'contact_title',
      'dba_name',
      'description_of_operations',
      'ein',
      'entity_type',
      'fiscal_year_end',
      'formation_date',
      'formation_state',
      'futa_year',
      'owners',
      'industry',
      'is_501c3_organization',
      'legal_name',
      'llc_structure',
      'naics_code',
      'non_profit',
      'payment_intent_id',
      'payroll_frequency',
      'previous_legal_name',
      'previous_entity_type_change_date',
      'previous_entity_type_fein',
      'previous_entity_type_state_jurisdiction_ein',
      'purpose',
      'redirect_uri',
      'registration_reason',
      'sos_business_name',
      'sos_registration_selection',
      'taxed_as_entity_type'
    ]),
    legal_entity_changed:
      applicationJson.registration_reason === CHANGED_ENTITY_TYPE,
    document_501c3: applicationJson.document_501c3_url,
    foreign_qualifications: applicationJson.saved_foreign_qualifications || [],
    tax_registrations: applicationJson.saved_tax_registrations || [],
    jurisdictions: [...stateJurisdictions, ...localJurisdictions],
    primary_address: applicationJson.addresses.find(
      (a: Address) => a.type === ADDRESS_TYPES.primary
    ),
    mailing_address: applicationJson.addresses.find(
      (a: Address) => a.type === ADDRESS_TYPES.mailing
    ),
    previous_primary_address: applicationJson.addresses.find(
      (a: Address) => a.type === ADDRESS_TYPES.previous_primary
    ),
    secondary_addresses: applicationJson.addresses
      .filter((a: Address) => a.type === ADDRESS_TYPES.secondary)
      .reduce((a: any, address: Address) => {
        const state = address.state || 'none'
        if (!a[state]) {
          a[state] = []
        }
        a[state].push(address)

        return a
      }, {}),
    questions: applicationJson.questions.reduce((a: any, question: any) => {
      if (isLocalQuestion(question)) {
        const localJurisdictionSlug = question.jurisdiction_slugs[0]

        if (!a[localJurisdictionSlug]) {
          a[localJurisdictionSlug] = {}
        }
        a[localJurisdictionSlug][question.key] = question.value

        return a
      }

      if (!a[question.state]) {
        a[question.state] = {}
      }
      a[question.state][question.key] = question.document_url || question.value

      return a
    }, {}),
    local_details: applicationJson.local_details.reduce(
      (a: any, local_detail: any) => {
        a[local_detail.jurisdiction_slug] = {
          ...local_detail,
          payroll_date: local_detail.payroll_date
            ? moment(local_detail.payroll_date).format('MM/DD/YYYY')
            : null,
          start_date_in_jurisdiction: local_detail.start_date_in_jurisdiction
            ? moment(local_detail.start_date_in_jurisdiction).format(
                'MM/DD/YYYY'
              )
            : null
        }

        return a
      },
      {}
    )
  }
}

const isLocalQuestion = (question: any) => {
  if ((question.jurisdiction_slugs || []).length !== 1) {
    return false
  }

  const jurisdiction_slug = question.jurisdiction_slugs[0].toLowerCase()

  if (
    jurisdiction_slug.includes(UI_JURISDICTION_SLUG_IDENTIFIER) ||
    jurisdiction_slug.includes(WH_JURISDICTION_SLUG_IDENTIFIER)
  ) {
    return false
  }

  return true
}

export const prefillInfo = (): Record<string, any> => {
  return {
    contact_email: 'james@middesk.com',
    contact_name: 'James Wang',
    contact_phone_number: '(555) 555-5555',
    non_profit: 'false',
    dba_name: 'Deskmid',
    ein: '12-3123123',
    entity_type: 'c_corporation',
    tax_registrations: [{ state: 'CA' }],
    owners: [
      {
        address: {
          address_line1: '2361 Greenwood Ave',
          city: 'Calistoga',
          postal_code: '94515-1031',
          state: 'CA'
        },
        name: 'Kyle Mack',
        titles: ['President', 'Chief Executive Officer'],
        signatory: true,
        ssn: '123-12-3123',
        email: 'kyle@midde.sk',
        dob: '01/01/1990',
        ownership_percentage: 10,
        object: 'agent_individual'
      }
    ],
    primary_address: {
      address_line1: '577 Howard St',
      city: 'San Francisco',
      postal_code: '94105-4635',
      state: 'CA',
      address_type: ADDRESS_TYPES.primary,
      full_address: '577 Howard St San Francisco, CA 94105-4635'
    },
    addresses: [
      {
        address_line1: '577 Howard St',
        city: 'San Francisco',
        postal_code: '94105-4635',
        state: 'CA',
        address_type: ADDRESS_TYPES.primary,
        full_address: '577 Howard St San Francisco, CA 94105-4635'
      }
    ],
    formation_date: '05/21/2019',
    formation_state: 'DE',
    industry: 'Mushroom Production',
    legal_name: 'Middesk',
    description_of_operations: 'Lorem ipsum',
    naics_code: 111411,
    purpose:
      'This U.S. industry comprises establishments primarily engaged in growing mushrooms under cover in mines underground, or in other controlled environments.',
    acquired: 'false',
    fiscal_year_end: '12/31/2019',
    legal_entity_changed: 'false',
    futa_year: '2020'
  }
}

export const transfersPrefillInfo = (): Record<string, any> => {
  return {
    contact_email: 'james@middesk.com',
    contact_name: 'James Wang',
    contact_phone_number: '(555) 555-5555',
    ein: '12-3123123',
    entity_type: 'c_corporation',
    formation_date: '05/21/2019',
    formation_state: 'DE',
    registered_states: [
      { abbr: 'CA', name: 'California' },
      { abbr: 'NY', name: 'New York' }
    ],
    legal_name: 'Middesk',
    sos_business_name: 'Middesk, Inc.',
    primary_address: {
      address_line1: '577 Howard St',
      city: 'San Francisco',
      postal_code: '94105-4635',
      state: 'CA',
      address_type: ADDRESS_TYPES.primary
    },
    mailing_address: {
      address_line1: '577 Howard St',
      city: 'San Francisco',
      postal_code: '94105-4635',
      state: 'CA',
      address_type: ADDRESS_TYPES.mailing
    },
    signatory: {
      name: 'Kyle Mack',
      email: 'kyle@midde.sk',
      title: 'Chief Executive Officer',
      is_current_user: true,
      authorized: true,
      signature: TEST_SIGNATURE
    },
    CA: {
      ca_edd_account_email: 'kyles@midde.sk',
      ca_edd_account_password: 'Password123*',
      ca_edd_account_password_confirm: 'Password123*',
      ca_edd_account_username: 'kyle-middesk',
      ca_edd_last_tax_payment_made: '10000',
      ca_edd_number: '12345678'
    },
    NY: {
      ny_employer_registration_number: '1234567'
    }
  }
}

export const openFAQ = () => {
  amplitude
    .getInstance()
    .logEvent('faq-clicked', null, () =>
      window.open('https://help.middesk.com/', '_blank')?.focus()
    )
}

export const getTaxAgencyRegistrationStatusLabel = (status: string) => {
  switch (status) {
    case INFORMATION_REQUESTED:
      return 'Action required'
    default:
      return capitalize(status.replaceAll('_', ' '))
  }
}

export const isLlcEntityType = (entityType: string | undefined): boolean => {
  const base = (entityType || '').toLowerCase()

  return base.includes(LLC_PREFIX) || base.includes(LLC_LONG)
}

export const isCorporation = (entityType: string | undefined): boolean => {
  return (entityType || '').toLowerCase() === CORPORATION
}

export const entityTypeFromLegalName = (legalName?: string) => {
  if (!legalName) return null

  if (legalName.toLowerCase().match(/llc$/)) {
    return ENTITY_TYPES.LLC
  } else if (
    legalName.toLowerCase().match(/(inc\.*|corp\.*|co\.*|limited|ltd\.*)$/)
  ) {
    return ENTITY_TYPES.CORPORATION
  }

  return null
}

export const EMPTY_SHARE: Stock = {
  series: undefined,
  class: undefined,
  number: undefined,
  par_value: undefined
}
const DEFAULT_STOCK_TABLE_VALUE = {
  issued_shares: [EMPTY_SHARE],
  authorized_shares: [EMPTY_SHARE]
}
export const getStockTable = (stockTable?: StockTable): StockTable => {
  if (!stockTable?.value) {
    return {
      ...stockTable,
      value: DEFAULT_STOCK_TABLE_VALUE
    }
  }

  return stockTable
}

export const getDowntimeNotice = (agency: StateAgency) => {
  const {
    agency_name,
    downtime_start,
    downtime_end,
    downtime_status_copy
  } = agency
  const notice = `Note: The ${agency_name} ${
    downtime_status_copy || 'is not accepting registrations'
  }`

  // Downtime not set for Agency
  if (!downtime_start && !downtime_status_copy) {
    return ''
  }

  // Return general notice without dates
  if (!downtime_start && !downtime_end) {
    return notice
  }

  const downtime_start_moment = moment(downtime_start)
  const downtime_end_moment = moment(downtime_end)

  // Downtime has ended
  if (downtime_end && moment().isAfter(downtime_end_moment, 'day')) {
    return ''
  }

  if (!downtime_end) {
    return `${notice} starting on ${downtime_start_moment.format('M/D')}.`
  } else if (downtime_start_moment.isSame(downtime_end_moment, 'day')) {
    return `${notice} on ${downtime_start_moment.format('M/D')}.`
  } else {
    return `${notice} between ${downtime_start_moment.format(
      'M/D'
    )} - ${downtime_end_moment.format('M/D')}.`
  }
}

const generateRandomString = () => {
  const length = Math.floor(Math.random() * 20)
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz '
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const generateRandomDate = () => {
  const today = new Date()
  const randomNum = Math.floor(Math.random() * 10000)
  today.setDate(today.getDate() - randomNum)
  return [
    padStart(String(today.getMonth() + 1), 2, '0'),
    padStart(String(today.getDate()), 2, '0'),
    today.getFullYear()
  ].join('/')
}

export const getStateQuestionPrefills = (questions: Array<Question>) => {
  return questions.reduce(
    (map: { [encodedKey: string]: any }, question: Question) => {
      const {
        type,
        options
      }: {
        label: string
        type: string
        options?: string[]
        key: string
      } = question

      const key = question.key
      if (type === 'number') {
        map[key] = Math.floor(Math.random() * 11)
      } else if (type === 'text') {
        map[key] = generateRandomString()
      } else if (type === 'date') {
        map[key] = generateRandomDate()
      } else if (type === 'boolean') {
        map[key] = ['true', 'false'][Math.floor(Math.random() * 2)]
      } else if (type === 'select' && options) {
        map[key] = options[Math.floor(Math.random() * options.length)]
      } else if (type === 'address') {
        map[key] = {
          address_type: 'primary',
          address_line1: '10710 N Torrey Pines Rd',
          address_line2: '',
          city: 'San Diego',
          state: 'CA',
          postal_code: '92037-1035',
          full_address: '10710 N Torrey Pines Rd San Diego, CA 92037-1035'
        }
      }
      return map
    },
    {}
  )
}

export const getActionIcon = (action: Action): string => {
  return APPLICATION_ACTION_TYPES.includes(action.type) ? 'clipboard' : 'info'
}

export const getActionSubheaderColor = (action: Action): string => {
  return ACTION_TYPES.ANNUAL_FILING === action.type ? 'red' : 'karl'
}

export const getActionUrl = (action: Action, companyId: string): string => {
  const { id, type } = action

  switch (type) {
    case ACTION_TYPES.NEW_APPLICATION:
      return `/applications/${id}`
    case ACTION_TYPES.TRANSFER_APPLICATION:
      return `/transfers/${id}`
    case ACTION_TYPES.INFO_REQUEST:
      return `/info_requests/${id}`
    case ACTION_TYPES.ANNUAL_FILING:
      return `/companies/${companyId}/confirm-information`
    default:
      return ''
  }
}

export const isGuestAccessiblePath = (path: string): boolean =>
  [
    GUEST_APPLICATION_ROUTE,
    GUEST_TRANSFER_ROUTE,
    GUEST_INVITATIONS_ROUTE,
    DEPRECATED_INVITATIONS_ROUTE,
    SOS_REGISTRATION_ROUTE,
    SOS_CONFIRMATION_ROUTE
  ].some(routePattern => path.match(routePattern))

export const tatMessage = (averageTat: number) => {
  if (averageTat === 0) {
    return 'immediately'
  } else if (averageTat < 7) {
    return `${pluralize('business day', Math.round(averageTat), true)}`
  } else {
    return `${pluralize('week', Math.round(averageTat / 7), true)}`
  }
}

const TEXT_FIELD_TYPES = new Set([
  'date',
  'ein',
  'masked',
  'number',
  'password',
  'phone',
  'ssn',
  'text',
  'textarea',
  'usd'
])

const isValidTextFieldType = (type?: string): type is TextFieldProps['type'] =>
  TEXT_FIELD_TYPES.has(type || '')

export const getTextFieldType = (type?: string): TextFieldProps['type'] =>
  isValidTextFieldType(type) ? type : 'text'

export const localSuggestions = [
  {
    naics_code: '485310',
    sic_codes: ['4121', '4899'],
    title: 'Taxi and Ridesharing Services',
    description:
      'This industry comprises establishments primarily engaged in providing passenger transportation by automobile or van, not operated over regular routes and on regular schedules. Establishments of taxicab owner/operators, taxicab fleet operators, taxicab organizations, ridesharing services (including arrangement services), and ride hailing services (including arrangement services) are included in this industry.',
    examples: [
      'Cab (i.e., taxi) services',
      'Taxicab dispatch services',
      'Taxicab fleet operators',
      'Taxicab organizations',
      'Taxicab owner-operators',
      'Taxicab services'
    ],
    xref: ['485991', '485320', '485999', '488999', '532111'],
    aliases: [],
    level: 6,
    sba_size_standards: {
      employee_count: null,
      millions_of_dollars: 16.5
    }
  },
  {
    naics_code: '485320',
    sic_codes: ['4119'],
    title: 'Limousine Service',
    description:
      'This industry comprises establishments primarily engaged in providing an array of specialty and luxury passenger transportation services via limousine or luxury sedan generally on a reserved basis. These establishments do not operate over regular routes and on regular schedules.',
    examples: [
      'Automobile rental with driver (except shuttle service, taxis)',
      'Hearse rental with driver',
      'Limousine services (except shuttle services)',
      'Limousines for hire with driver (except taxis)',
      'Luxury automobiles for hire with driver (except taxis)',
      'Passenger limousine rental with driver (except shuttle service, taxi)',
      'Passenger van rental with driver (except shuttle service, taxi)'
    ],
    xref: ['485310', '485999'],
    aliases: [],
    level: 6,
    sba_size_standards: {
      employee_count: null,
      millions_of_dollars: 16.5
    }
  },
  {
    naics_code: '921130',
    sic_codes: ['9311'],
    title: 'Public Finance Activities',
    description:
      'This industry comprises government establishments primarily engaged in public finance, taxation, and monetary policy. Included are financial administration activities, such as monetary policy; tax administration and collection; custody and disbursement of funds; debt and investment administration; auditing activities; and government employee retirement trust fund administration.',
    examples: [
      "Assessor's offices, tax",
      'Board of Governors, Federal Reserve',
      'Budget agencies, government',
      "Controllers' and comptrollers' offices, government",
      'Customs bureaus',
      'Federal Reserve Board of Governors',
      'Gambling control boards, nonoperating',
      'Internal Revenue Service',
      'Lottery control boards, nonoperating',
      "Property tax assessors' offices",
      'State tax commissions',
      'Taxation departments',
      "Treasurers' offices, government"
    ],
    xref: ['923130', '926150', '521110'],
    aliases: [],
    level: 6,
    sba_size_standards: {
      employee_count: null,
      millions_of_dollars: null
    }
  },
  {
    naics_code: '541213',
    sic_codes: ['7291'],
    title: 'Tax Preparation Services',
    description:
      'This U.S. industry comprises establishments (except offices of CPAs) engaged in providing tax return preparation services without also providing accounting, bookkeeping, billing, or payroll processing services. Basic knowledge of tax law and filing requirements is required.',
    examples: [
      'Income tax compilation services',
      'Income tax return preparation services',
      'Tax return preparation services'
    ],
    xref: ['541211', '541214', '541219', '522320', '518210'],
    aliases: [],
    level: 6,
    sba_size_standards: {
      employee_count: null,
      millions_of_dollars: 22
    }
  }
]

type ThemeColorVariant =
  | 'blue'
  | 'green'
  | 'orange'
  | 'purple'
  | 'red'
  | 'yellow'
  | 'karl'

export const tarStatusToComponentColor = (
  status: string
): {
  color: string
  name: ThemeColorVariant
} => {
  switch (status) {
    case INFORMATION_REQUESTED:
      return {
        color: colors.red,
        name: 'red'
      }
    case BLOCKED_BY_GOVERNMENT:
      return {
        color: colors.orange,
        name: 'orange'
      }
    case MIDDESK_PROCESSING:
      return {
        color: colors.blue,
        name: 'blue'
      }
    case GOVERNMENT_PROCESSING:
      return {
        color: colors.yellow,
        name: 'yellow'
      }
    case COMPLETED:
      return {
        color: colors.green,
        name: 'green'
      }
    default:
      return {
        color: colors.karl,
        name: 'karl'
      }
  }
}

export const individualOwners = (owners?: Owner[]) =>
  (owners || []).filter(
    ({ object }) => object === AGENT_INDIVIDUAL
  ) as Individual[]

const individualOwnerTitles = (owners?: Owner[]) =>
  compact(
    individualOwners(owners)
      .map(({ titles }) => titles)
      .flat()
  ) as JobTitle[]

export const hasCeoPresidentOrVP = (owners?: Owner[]) =>
  individualOwnerTitles(owners).some(title =>
    [
      JOB_TITLES.CHIEF_EXECUTIVE_OFFICER,
      JOB_TITLES.PRESIDENT,
      JOB_TITLES.VICE_PRESIDENT
    ].includes(title)
  )

export const hasSignatory = (owners?: Owner[]) =>
  (owners || []).some(o => o.object === AGENT_INDIVIDUAL && o.signatory)

export const getTotalOwnershipPercentage = (owners: Array<Owner>): number => {
  return owners.reduce(
    (partialSum, ind) => (partialSum += ind.ownership_percentage || 0),
    0
  )
}

export const hasSecretary = (owners: Owner[]) =>
  individualOwnerTitles(owners).some((title: string) =>
    SECRETARY_ROLES.includes(title)
  )

export const hasCFO = (owners: Owner[]) =>
  individualOwnerTitles(owners).some((title: string) =>
    TREASURER_ROLES.includes(title)
  )

export const hasCeoOrPresident = (owners: Owner[]) => {
  const required_titles_oneof = [
    JOB_TITLES.CHIEF_EXECUTIVE_OFFICER,
    JOB_TITLES.PRESIDENT
  ]
  return individualOwnerTitles(owners).some((title: JobTitle) => {
    return required_titles_oneof.includes(title)
  })
}

export const has100PercentOwnership = (owners: Owner[]) =>
  getTotalOwnershipPercentage(owners) === 100

export const has100PercentOrThreeOwners = (owners: Owner[]) =>
  has100PercentOwnership(owners) ||
  owners.filter((o: any) => (o.ownership_percentage || 0) > 0).length >= 3

export const has100PercentOrThreeOfficers = (owners: Owner[]) =>
  has100PercentOwnership(owners) || individualOwners(owners).length >= 3

export const hasThreeOfficers = (owners: Owner[]) =>
  individualOwners(owners).length >= 3

export const hasDirector = (owners: Owner[]) =>
  individualOwners(owners).some(({ director }) => director)

export const hasLlcOwner = (owners: Owner[]) =>
  individualOwners(owners).some(({ llc_owner }) => llc_owner)

export const hasMinimumTwoOfficers = (owners: Owner[]) =>
  individualOwners(owners).length >= 2

export const comprehensiveTaxTypes = (taxTypes?: RegistrationType[]) =>
  taxTypes && !isEmpty(taxTypes)
    ? taxTypes
    : ([
        STATE_TAX_WITHHOLDINGS_TYPE,
        STATE_UNEMPLOYMENT_INSURANCE_TYPE
      ] as RegistrationType[])

export const taxTypeIntentKey = (taxType: string) => `${taxType}_selection`
export const getTaxTypesByIntent = ({ tax_registrations }: any) => {
  if (!tax_registrations) return {}

  const tr = tax_registrations[0] as any

  return PAYROLL_REGISTRATION_TYPES.reduce(
    (obj: { [intent: string]: RegistrationType[] }, taxType: string) => {
      const intent = tr[taxTypeIntentKey(taxType)]
      const taxTypes =
        taxType === BOTH_SUI_AND_SWH_TYPE
          ? [STATE_TAX_WITHHOLDINGS_TYPE, STATE_UNEMPLOYMENT_INSURANCE_TYPE]
          : [taxType]

      if (!intent) {
        return obj
      }

      return {
        ...obj,
        [intent]: [...(obj[intent] || []), ...taxTypes]
      }
    },
    {}
  )
}

export const isPaychex = (partner?: { name: string }) =>
  partner?.name === 'Paychex'

export const blockedByPayrollDateMessage = (
  agency: Agency,
  payrollDateString: string
) => {
  const { name, payroll_date_blocking } = agency

  if (!payroll_date_blocking) return ''

  const payrollDate = moment(payrollDateString)

  if (payrollDate.isSameOrBefore()) return ''

  return `The ${name} requires that the first payroll date has passed before \
    registering. Middesk will submit to this agency after your payroll date of \
    ${payrollDate.format('MMMM D YYYY')} passes.`
}

export const blockedByHiredDateMessage = (
  agency: Agency,
  hiredDateString: string
) => {
  const { name, hire_date_blocking } = agency

  if (!hire_date_blocking) return ''

  const hiredDate = moment(hiredDateString)

  if (hiredDate.isSameOrBefore()) return ''

  return `The ${name} requires that the employee's hired date has passed before \
    registering. Middesk will submit to this agency after your hired date of \
    ${hiredDate.format('MMMM D YYYY')} passes.`
}

export const getEntityTypeKey = (entity_type: string) => {
  return (
    Object.keys(ENTITY_TYPES).find(
      key => ENTITY_TYPES[key as OrganizationEntityType] === entity_type
    ) || ''
  )
}

const beginningOfQuarter = (year: number, quarter: string): moment.Moment => {
  switch (quarter) {
    case 'Q1':
      return moment(`${year}-01-01`)
    case 'Q2':
      return moment(`${year}-04-01`)
    case 'Q3':
      return moment(`${year}-07-01`)
    case 'Q4':
      return moment(`${year}-10-01`)
    default:
      return moment(`${year}-01-01`)
  }
}

const endOfQuarter = (year: number, quarter: string): moment.Moment => {
  switch (quarter) {
    case 'Q1':
      return moment(`${year}-03-31`)
    case 'Q2':
      return moment(`${year}-06-30`)
    case 'Q3':
      return moment(`${year}-09-30`)
    case 'Q4':
      return moment(`${year}-12-31`)
    default:
      return moment(`${year}-12-31`)
  }
}

export const getDateFromDatePart = (
  earliest: boolean,
  datePart: DatePartQuestion
): moment.Moment | false => {
  const { year, quarter, month, day } = datePart

  if (!year) {
    return false
  }

  if (day && month) {
    return moment(`${year}-${month}-${day}`, 'YYYY-MMMM-D')
  }

  if (month) {
    return moment(`${year}-${month}-01`, 'YYYY-MMMM-DD')
      .add(earliest ? 0 : 1, 'month')
      .subtract(earliest ? 0 : 1, 'day')
  }

  if (quarter) {
    return earliest
      ? beginningOfQuarter(year, quarter)
      : endOfQuarter(year, quarter)
  }

  return moment(`${year}-${earliest ? '01-01' : '12-31'}`)
}

export const shouldRaiseDateError = (
  questionDate: moment.Moment | false,
  comparisonDate: moment.Moment | false,
  validation: DateValidation
): boolean => {
  const { before, exclusive } = validation

  if (!questionDate || !comparisonDate) return false

  if (
    // Exclusively before
    before &&
    exclusive &&
    !questionDate.isBefore(comparisonDate)
  ) {
    return true
  }
  if (
    // On or before
    before &&
    !exclusive &&
    questionDate.isAfter(comparisonDate)
  ) {
    return true
  }
  if (
    // Exclusively after
    !before &&
    exclusive &&
    !questionDate.isAfter(comparisonDate)
  ) {
    return true
  }
  if (
    // On or after
    !before &&
    !exclusive &&
    questionDate.isBefore(comparisonDate)
  ) {
    return true
  }

  return false
}

export const localJurisdictions = (jurisdictions: string[] | undefined) => {
  return (jurisdictions || []).filter(jurisdiction => {
    return !PAYROLL_REGISTRATION_TYPES.includes(jurisdiction)
  })
}

export const stateJurisdictions = (jurisdictions: string[] | undefined) => {
  return (jurisdictions || []).filter(jurisdiction => {
    return PAYROLL_REGISTRATION_TYPES.includes(jurisdiction)
  })
}

export const localTaxLineItems = (
  pricingData: TransactionalPricingData | undefined,
  localJurisdictionRegistrations: LocalRegistrationPageProps[] | undefined,
  localJurisdictionsInState: any[] | undefined
) =>
  localJurisdictionRegistrations?.map(localJurisdictionRegistration => {
    const localJurisdiction = (localJurisdictionsInState || []).find(
      local => local.slug == localJurisdictionRegistration.jurisdictionSlug
    )

    return {
      type: 'local_tax',
      label: `${localJurisdiction.name} account creation`,
      amount_cents: pricingData?.local_taxes_price_in_cents || 0
    }
  }) || []
